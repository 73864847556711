import React from 'react';
import './Brighten.css';

interface BrightenProps {
    element: React.ReactElement<any>;
}

const Brighten: React.FC<BrightenProps> = ({ element }) => {
    return (
        <div className="brighten">
            {element}
        </div>
    );
};

export default Brighten;
