import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Typography, Box, Drawer, List, ListItem, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

const Header: React.FC = () => {
    const [drawerOpen, setDrawerOpen] = useState(false);

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };

    const drawerList = () => (
        <Box
            sx={{
                background: '#3c4f73',
                width: 250,
                height: '100%',
            }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <List>
                <ListItem component={Link} to="/">
                    <ListItemText primary="Home" style={{ textDecoration: 'none', color: 'white', padding: '10px 20px' }} />
                </ListItem>
                <ListItem component={Link} to="/approach">
                    <ListItemText primary="Approach" style={{ textDecoration: 'none', color: 'white', padding: '10px 20px' }} />
                </ListItem>
                <ListItem component={Link} to="/values">
                    <ListItemText primary="Values" style={{ textDecoration: 'none', color: 'white', padding: '10px 20px' }} />
                </ListItem>
                <ListItem component={Link} to="/story">
                    <ListItemText primary="Story" style={{ textDecoration: 'none', color: 'white', padding: '10px 20px' }} />
                </ListItem>
                <ListItem component={Link} to="/services">
                    <ListItemText primary="Services" style={{ textDecoration: 'none', color: 'white', padding: '10px 20px' }} />
                </ListItem>
                <ListItem component={Link} to="/innovations">
                    <ListItemText primary="Team Innovations" style={{ textDecoration: 'none', color: 'white', padding: '10px 20px' }} />
                </ListItem>
                <ListItem component={Link} to="/healthcare">
                    <ListItemText primary="Healthcare Solutions" style={{ textDecoration: 'none', color: 'white', padding: '10px 20px' }} />
                </ListItem>
                <ListItem component={Link} to="/team">
                    <ListItemText primary="Team" style={{ textDecoration: 'none', color: 'white', padding: '10px 20px' }} />
                </ListItem>
                <ListItem component={Link} to="/contact">
                    <ListItemText primary="Contact" style={{ textDecoration: 'none', color: 'white', padding: '10px 20px' }} />
                </ListItem>
            </List>
        </Box>
    );

    return (
        <AppBar
            position="static"
            sx={{
                background: '#3c4f73',
                color: '#fff',
                backgroundImage: `url('/images/background-dots.png')`,
                fontSize: '14pt',
            }}
        >
            <Toolbar sx={{ justifyContent: 'space-between' }}>
                <Box>
                    <Link to="/">
                        <img
                            src="/images/Logo-02.png"
                            alt="SparkInterventions"
                            style={{
                                width: '250px',
                                height: 'auto',
                            }}
                        />
                    </Link>
                </Box>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={toggleDrawer(true)}
                    sx={{ display: { xs: 'block', md: 'none' } }}
                >
                    <MenuIcon />
                </IconButton>
                <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
                    <Link to="/" style={{ textDecoration: 'none', color: 'white', padding: '10px 20px' }}>Home</Link>
                    <Link to="/approach" style={{ textDecoration: 'none', color: 'white', padding: '10px 20px' }}>Approach</Link>
                    <Link to="/values" style={{ textDecoration: 'none', color: 'white', padding: '10px 20px' }}>Values</Link>
                    <Link to="/story" style={{ textDecoration: 'none', color: 'white', padding: '10px 20px' }}>Story</Link>
                    <Link to="/services" style={{ textDecoration: 'none', color: 'white', padding: '10px 20px' }}>Services</Link>
                    <Link to="/innovations" style={{ textDecoration: 'none', color: 'white', padding: '10px 20px' }}>Team Innovations</Link>
                    <Link to="/healthcare" style={{ textDecoration: 'none', color: 'white', padding: '10px 20px' }}>Healthcare Solutions</Link>
                    <Link to="/team" style={{ textDecoration: 'none', color: 'white', padding: '10px 20px' }}>Team</Link>
                    <Link to="/contact" style={{ textDecoration: 'none', color: 'white', padding: '10px 20px' }}>Contact</Link>
                </Box>
            </Toolbar>
            <Drawer
                anchor="left"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
            >
                {drawerList()}
            </Drawer>
        </AppBar>
    );
};

export default Header;
