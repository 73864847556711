import React from 'react';
import './Center.css';

interface CenterProps {
    element: React.ReactElement<any>;
}

const Center: React.FC<CenterProps> = ({ element }) => {
    return (
        <div className="center">
            {element}
        </div>
    );
};

export default Center;
