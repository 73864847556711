import React from 'react';
import './ServicesCard.css';

interface OurServicesCardProps {
  text: string;
  image: React.ReactElement;
  desc: string;
}

const OurServicesCard: React.FC<OurServicesCardProps> = ({ text, image, desc }) => {
  return (
    <div className="home-service-card">
      <div className="image-wrapper">
        {image}
      </div>
      <div className="title-wrapper">
        <p>{text}</p>
      </div>
      <div className="hover">{desc}</div>
    </div>
  );
};

export default OurServicesCard;
