import React from 'react';
import './Text.css';

interface TextProps {
    texts: React.ReactElement<HTMLHeadingElement | HTMLParagraphElement>[];
    color?: string;
}

const Text: React.FC<TextProps> = ({ texts, color }) => {
    return (
        <div className="text" style={{ color: color }}>
            {texts.map((element, index) => 
                React.cloneElement(element, { key: index })
            )}
        </div>
    )
}

export default Text;
