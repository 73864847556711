import React from 'react';
import Page from '../components/Page/Page';
import Border from '../components/Border/Border';
import TwoColumns from '../components/TwoColumns/TwoColumns';
import Text from '../components/Text/Text';
import Image from '../components/Image/Image';
import Accordion from '../components/Accordion/Accordion';
import Brighten from '../components/Brighten/Brighten';
import Flex40 from '../components/Flex40/Flex40';

const Innovations: React.FC = () => {
    return (
        <div style={{color: `#3c4f73`}}>
        <Page elements={[
            <Text texts={[
                <h1>Healthcare Solutions</h1>
            ]}/>,
            <div id={`autism-workplace-inclusion`}>
                <Border element={
                    <TwoColumns
                        leftAboveInMobile={true}
                        left={
                            <Flex40 element={
                                <Image
                                    src={`/images/healthcare/autism.png`}
                                    alt={`Autism Workplace Inclusion`}
                                />
                            }/>
                        }
                        right={
                            <Text texts={[
                                <h2>Autism Workplace Inclusion</h2>,
                                <h6>AI tools identify autistic talents in pattern recognition and problem-solving, while supporting social and sensory challenges, fostering inclusive innovation</h6>,
                                <ul>
                                    <li>AI identifies autistic talent based on individual strengths and unique cognitive skills</li>
                                    <li>AI-powered assessments reduce bias, focusing on skills like pattern recognition and attention to detail</li>
                                    <li>Personalized career support through AI-matched mentoring and learning opportunities</li>
                                    <li>Adaptive communication tools and sensory-friendly workplace adjustments</li>
                                </ul>,
                                <Accordion
                                    clickToOpen={
                                        <Brighten element={
                                            <p style={{ color: `#e08132` }}>
                                                Read More »
                                            </p>
                                        }/>
                                    }
                                    clickToClose={
                                        <Brighten element={
                                            <p style={{ color: `#e08132` }}>
                                                Read Less «
                                            </p>
                                        }/>
                                    }
                                    element={
                                        <div>
                                            <p>
                                                Sarah, a data analyst with autism, thrives at Data4Tech thanks to AI-driven inclusion initiatives.
                                                The company's AI-powered hiring process recognized her exceptional pattern recognition skills
                                                and creative problem-solving abilities, bypassing traditional interviews that she found
                                                challenging.
                                            </p>
                                            <p>
                                                Once hired, Sarah received personalized career support through an AI-matched mentor who
                                                understands her unique strengths and helps navigate workplace challenges. The AI system also
                                                suggested sensory-friendly adjustments to her work environment, reducing anxiety and stress.
                                                Adaptive AI communication tools help her interpret social cues and navigate team meetings,
                                                enabling her to contribute effectively to projects.
                                            </p>
                                            <p>
                                                As a result, Sarah excels in her role, bringing valuable insights to the company while feeling
                                                supported and included in the workplace. Data4Tech reports increased innovation and better
                                                problem-solving across teams since implementing these inclusive practices.
                                            </p>
                                        </div>
                                    }
                                />,
                            ]}/>
                        }
                    />
                }/>
            </div>,
            <div id={`dyslexia-workplace-inclusion`}>
                <Border element={
                    <TwoColumns
                        leftAboveInMobile={false}
                        left={
                            <Text texts={[
                                <h2>Dyslexia Workplace Inclusion</h2>,
                                <h6>AI recognizes dyslexic visual-spatial and creative strengths, providing adaptive tools for reading and organization, enhancing workplace performance</h6>,
                                <ul>
                                    <li>AI tools convert text to audio/visual formats and simplify tasks with visual aids.</li>
                                    <li>Continuous adaptation to individual preferences and performance enhances engagement and productivity.</li>
                                    <li>Organizations can use AI to adapt policy and procedure communications to formats better suited for the Dyslexic, enhancing productivity.</li>
                                    <li>AI-powered assistants offer on-demand guidance and help navigate workplace challenges.</li>
                                </ul>,
                                <Accordion
                                    clickToOpen={
                                        <Brighten element={
                                            <p style={{ color: `#e08132` }}>
                                                Read More »
                                            </p>
                                        }/>
                                    }
                                    clickToClose={
                                        <Brighten element={
                                            <p style={{ color: `#e08132` }}>
                                                Read Less «
                                            </p>
                                        }/>
                                    }
                                    element={
                                        <div>
                                            <p>
                                                Mark, a marketing professional with dyslexia, flourishes at CreativeCo with the help of AI-
                                                driven inclusion tools. He uses an AI assistant that converts lengthy reports and emails into
                                                easily digestible audio or visual formats, allowing him to process information more efficiently.
                                                When faced with complex written procedures or time management challenges, Mark relies on an
                                                AI-powered virtual assistant that provides step-by-step guidance and helps prioritize tasks.
                                            </p>
                                            <p>
                                                CreativeCo's AI system adapts company policies and communications into formats better suited
                                                for dyslexic employees, enhancing Mark's productivity and understanding. The AI also tailors
                                                his professional development plan, identifying courses and mentoring opportunities that align
                                                with his unique cognitive skills and visual-spatial strengths.
                                            </p>
                                            <p>
                                                As a result, Mark has become a top performer in his team, contributing innovative marketing
                                                strategies while feeling fully supported in his role. CreativeCo reports improved engagement and
                                                productivity across their neurodivergent workforce since implementing these inclusive AI tools.
                                            </p>
                                        </div>
                                    }
                                />,
                            ]}/>
                        }
                        right={
                            <Flex40 element={
                                <Image
                                    src={`/images/healthcare/dyslexia.png`}
                                    alt={`Dyslexia Workplace Inclusion`}
                                />
                            }/>
                        }
                    />
                }/>
            </div>,
        ]}/>
        </div>
    );
};

export default Innovations;
