import React from 'react';
import Page from '../components/Page/Page';
import Vertical from '../components/Vertical/Vertical';
import Border from '../components/Border/Border';
import TwoColumns from '../components/TwoColumns/TwoColumns';
import Text from '../components/Text/Text';
import Image from '../components/Image/Image';
import Flex40 from '../components/Flex40/Flex40';
import Center from '../components/Center/Center';

const Values: React.FC = () => {
    return (
        <div style={{color: `#3c4f73`}}>
        <Page elements={[
            <Text texts={[
                <h1>Our Values</h1>,
            ]}/>,
            <TwoColumns
                leftAboveInMobile={false}
                left={
                    <Vertical elements={[
                        <Border element={
                            <Text texts={[
                                <h4>Innovation</h4>,
                                <p>We constantly explore and integrate new technologies to revolutionize mental health care and learning disability support, offering transformative solutions.</p>,
                            ]}/>
                        }/>,
                        <Border element={
                            <Text texts={[
                                <h4>Empathy and Empowerment</h4>,
                                <p>We aim to empower individuals facing mental health challenges through inclusive and accessible solutions that provide early support for lifelong success.</p>,
                            ]}/>
                        }/>,
                    ]}/>
                }
                right={
                    <Flex40 element={
                        <Center element={
                            <Image
                                src={`/images/values/lightbulb6.jpg`}
                                alt={`image3`}
                                caption={`Reaching for Innovation: Empowering Through Empathy`}
                            />
                        }/>
                    }/>
                }
            />,
            <TwoColumns
                leftAboveInMobile={true}
                left={
                    <Flex40 element={
                        <Center element={
                            <Image
                                src={`/images/values/collaborate3.jpg`}
                                alt={`image4`}
                                caption={`Collaborative Effort: Ensuring Quality and Consistency`}
                            />
                        }/>
                    }/>
                }
                right={
                    <Vertical elements={[
                        <Border element={
                            <Text texts={[
                                <h4>Collaboration</h4>,
                                <p>Our diverse team of experts collaborates closely to deliver tailored, comprehensive solutions that meet our customers' unique needs.</p>,
                            ]}/>
                        }/>,
                        <Border element={
                            <Text texts={[
                                <h4>Quality and Consistency</h4>,
                                <p>We ensure our solutions are of the highest quality and provide consistent results, addressing variability in therapy and mental health care.</p>,
                            ]}/>
                        }/>,
                    ]}/>
                }
            />,
            <TwoColumns
                leftAboveInMobile={false}
                left={
                    <Vertical elements={[
                        <Border element={
                            <Text texts={[
                                <h4>Strategic Focus</h4>,
                                <p>We equip clients with the tools to navigate market complexities and maintain a competitive edge through strategic and market-focused approaches.</p>,
                            ]}/>
                        }/>,
                        <Border element={
                            <Text texts={[
                                <h4>Scientific Rigor</h4>,
                                <p>We commit to rigorous scientific research and data integrity, ensuring that our AI/ML models meet high performance and accuracy standards.</p>,
                            ]}/>
                        }/>,
                        <Border element={
                            <Text texts={[
                                <h4>Impact and Improvement</h4>,
                                <p>We focus on making a meaningful impact by improving outcomes and quality of life through early detection, personalized interventions, and ongoing support.</p>,
                            ]}/>
                        }/>,
                    ]}/>
                }
                right={
                    <Flex40 element={
                        <Center element={
                            <Image
                                src={`/images/values/strategy1.jpg`}
                                alt={`image5`}
                                caption={`Strategic Focus: Driving Impact and Improvement with Scientific Rigor`}
                            />
                        }/>
                    }/>
                }
            />,
        ]}/>
        </div>
    );
};

export default Values;
