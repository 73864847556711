import React from 'react';
import Page from '../components/Page/Page';
import Border from '../components/Border/Border';
import TwoColumns from '../components/TwoColumns/TwoColumns';
import Text from '../components/Text/Text';
import Image from '../components/Image/Image';
import Accordion from '../components/Accordion/Accordion';
import Brighten from '../components/Brighten/Brighten';
import Flex40 from '../components/Flex40/Flex40';

const Innovations: React.FC = () => {
    return (
        <div style={{color: `#3c4f73`}}>
        <Page elements={[
            <Text texts={[
                <h1>Team Innovations</h1>
            ]}/>,
            <div id={`therapists-companion`}>
                <Border element={
                    <TwoColumns
                        leftAboveInMobile={true}
                        left={
                            <Flex40 element={
                                <Image
                                    src={`/images/innovations/company-manager-focusing-hard-on-difficult-task-2023-11-27-05-08-46-utc.jpg`}
                                    alt={`Therapist's Companion`}
                                />
                            }/>
                        }
                        right={
                            <Text texts={[
                                <h2>Therapist's Companion</h2>,
                                <h6>AI Model Surpasses Human Performance in Cognitive Profiling</h6>,
                                <ul>
                                    <li>Current tools for mental health and learning disabilities fall short compared to human clinicians</li>
                                    <li>Our model meets/exceeds human performance in creating cognitive profiles</li>
                                    <li>Reduces reliance on human judgment, eliminating biases and inconsistencies</li>
                                    <li>Provides repeatable and reliable assessments, regardless of the clinician's condition</li>
                                    <li>Leads to more equitable and accurate cognitive profiling</li>
                                </ul>,
                                <Accordion
                                    clickToOpen={
                                        <Brighten element={
                                            <p style={{ color: `#e08132` }}>
                                                Read More »
                                            </p>
                                        }/>
                                    }
                                    clickToClose={
                                        <Brighten element={
                                            <p style={{ color: `#e08132` }}>
                                                Read Less «
                                            </p>
                                        }/>
                                    }
                                    element={
                                        <p>
                                            At Morning Corp., the Therapist's Companion is seamlessly integrated into the daily workflow,
                                            monitoring employees' communication for mental health signs. One day, it detects subtle changes
                                            in an employee's email tone and vocal stress during meetings, flagging potential early signs of burnout.
                                            An alert is sent to the therapist, who reviews the insights and schedules a confidential
                                            check-in with the employee to offer support.
                                        </p>
                                    }
                                />,
                            ]}/>
                        }
                    />
                }/>
            </div>,
            <div id={`socially-assistive-robots`}>
                <Border element={
                    <TwoColumns
                        leftAboveInMobile={false}
                        left={
                            <Text texts={[
                                <h2>Socially Assistive Robots that Express True Mental State</h2>,
                                <h6>Robots to enhance daily life for Parkinson's Disease persons</h6>,
                                <ul>
                                    <li>Detects emotions from speech patterns</li>
                                    <li>Expresses emotions through robot gestures and facial expressions</li>
                                    <li>Adjusts for individual speech variations, minimizing errors</li>
                                </ul>,
                                <Accordion
                                    clickToOpen={
                                        <Brighten element={
                                            <p style={{ color: `#e08132` }}>
                                                Read More »
                                            </p>
                                        }/>
                                    }
                                    clickToClose={
                                        <Brighten element={
                                            <p style={{ color: `#e08132` }}>
                                                Read Less «
                                            </p>
                                        }/>
                                    }
                                    element={
                                        <p>
                                            John, a 65-year-old with Parkinson's disease, uses a Socially Assistive Robot to enhance his interactions and autonomy. The robot monitors his emotional state, offers comfort, and engages him to improve his quality of life. It provides Mary, his caregiver, real-time feedback and educational modules on Parkinson’s management. This system significantly boosts John’s independence and Mary’s caregiving confidence, enhancing their overall well-being
                                        </p>
                                    }
                                />,
                            ]}/>
                        }
                        right={
                            <Flex40 element={
                                <Image
                                    src={`/images/innovations/robot-assistant-concept-woman-using-information-r-2023-11-27-05-34-03-utc.jpeg`}
                                    alt={`Socially Assistive Robots`}
                                />
                            }/>
                        }
                    />
                }/>
            </div>,
            <div id={`mental-health-support`}>
                <Border element={
                    <TwoColumns
                        leftAboveInMobile={true}
                        left={
                            <Flex40 element={
                                <Image
                                    src={`/images/innovations/ai-robot-consoling-a-sad-lonely-man-2023-11-27-05-08-22-utc.jpg`}
                                    alt={`Socially Assistive Robots`}
                                />
                            }/>
                        }
                        right={
                            <Text texts={[
                                <h2>Enhanced Mental Health Support Through AI</h2>,
                                <h6>Assistive technology to identify social isolation among older adults</h6>,
                                <ul>
                                    <li>Detects social isolation in the elderly by their speech and voice tone</li>
                                    <li>Adapts to individual speech variations</li>
                                </ul>,
                                <Accordion
                                    clickToOpen={
                                        <Brighten element={
                                            <p style={{ color: `#e08132` }}>
                                                Read More »
                                            </p>
                                        }/>
                                    }
                                    clickToClose={
                                        <Brighten element={
                                            <p style={{ color: `#e08132` }}>
                                                Read Less «
                                            </p>
                                        }/>
                                    }
                                    element={
                                        <p>
                                            Emily, a 78-year-old living alone, uses the Enhanced Mental Health Support AI to monitor her daily interactions and behaviors. The AI detects signs of loneliness, such as reduced communication and prolonged inactivity, and alerts her caregivers and family when intervention is needed. This tool provides timely support and community resources, helping Emily stay connected and engaged. As a result, Emily experiences reduced loneliness and improved emotional well-being, significantly enhancing her quality of life.
                                        </p>
                                    }
                                />,
                            ]}/>
                        }
                    />
                }/>
            </div>,
        ]}/>
        </div>
    );
};

export default Innovations;
