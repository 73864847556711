import React from 'react';
import OurServicesCard from './ServicesCard';
import './Services.css';

interface OurServicesProps {
  cards: React.ReactElement<typeof OurServicesCard>[];
}

const OurServices: React.FC<OurServicesProps> = ({ cards }) => {
    return (
        <div className="home-services">
            <h2>Our Services Deliver Tailored Solutions</h2>
            <div className="card-container">
                {cards.map((element, index) => 
                    React.cloneElement(element, { key: index })
                )}
            </div>
        </div>
    );
};

export default OurServices;
