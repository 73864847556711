import React from 'react';
import './Icon.css';

interface IconProps {
    src: string;
    alt: string;
}

const Icon: React.FC<IconProps> = ({ src, alt }) => {
    return (
        <div className="icon">
            <div className="icon-border">
                <img src={src} alt={alt} />
            </div>
        </div>
    )
}

export default Icon;
