import React from 'react';
import './Flex40.css';

interface Flex40Props {
    element: React.ReactElement<any>;
}

const Flex40: React.FC<Flex40Props> = ({ element }) => {
    return (
        <div className="flex40">
            {element}
        </div>
    )
}

export default Flex40;
