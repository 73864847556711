import React from 'react';
import Page from '../components/Page/Page';
import TwoColumns from '../components/TwoColumns/TwoColumns';
import Text from '../components/Text/Text';
import Image from '../components/Image/Image';
import Border from '../components/Border/Border';
import Flex40 from '../components/Flex40/Flex40';
import Center from '../components/Center/Center';

const Story: React.FC = () => {
    return (
        <div style={{color: `#3c4f73`}}>
        <Page elements={[
            <Text texts={[
                <h1>Our Story</h1>,
            ]}/>,
            <Border element={
                <TwoColumns
                    leftAboveInMobile={false}
                    left={
                        <Text texts={[
                            <p>
                                The story of Spark Interventions began long before its official formation on April 4th, 2024.
                                It traces back to the diverse classrooms of Northeastern University in Boston, where our
                                founder, Andy Valenti, taught young adults from various cultural backgrounds.
                            </p>,
                            <p>
                                His fascination with how culture, childhood experiences, and brain-wiring influence learning led
                                him to Tufts University, where he enrolled in their Cognitive Science doctoral program. As a
                                graduate research assistant at Tufts, Andy developed a companion robot to help individuals
                                with learning disabilities and Parkinson's Disease, marking a significant milestone in his
                                career.
                            </p>,
                        ]}/>
                    }
                    right={
                        <Flex40 element={
                            <Center element={
                                <Image
                                    src={`/images/story/young-african-american-woman-sitting-in-the-office-2024-02-01-05-55-24-utc.jpg`}
                                    alt={`image1`}
                                />
                            }/>
                        }/>
                    }
                />
            }/>,
            <Border element={
                <TwoColumns
                    leftAboveInMobile={true}
                    left={
                        <Flex40 element={
                            <Center element={
                                <Image
                                    src={`/images/story/robot-supporting-a-girl-touching-her-shoulder-2023-11-27-04-59-10-utc.jpg`}
                                    alt={`image2`}
                                />
                            }/>
                        }/>
                    }
                    right={
                        <Text texts={[
                            <p>
                                Realizing AI's potential to improve the lives of marginalized individuals, particularly those
                                with mental health issues and learning disabilities, Andy founded Spark Interventions with
                                the vision of "Using AI to empower potential through early support enabling lifelong
                                success."
                            </p>,
                            <p>
                                The company brings together senior experts in mental health care, cognitive science, and robotics
                                to create innovative, tailored solutions. By leveraging diverse expertise, Spark Interventions
                                enhances learning processes and mental well-being through cutting-edge AI technology,
                                providing comprehensive support for those in need.
                            </p>,
                        ]}/>
                    }
                />
            }/>,
        ]}/>
        </div>
    );
};

export default Story;
