import React from 'react';
import './Vertical.css';

interface VerticalProps {
    elements: React.ReactElement<any>[];
}

const Vertical: React.FC<VerticalProps> = ({ elements }) => {
    return (
        <div className="vertical">
            {elements.map((element, index) => 
                React.cloneElement(element, { key: index })
            )}
        </div>
    )
}

export default Vertical;
