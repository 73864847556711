import React, { useState } from 'react';

interface TextProps {
    clickToOpen: React.ReactElement<any>;
    clickToClose: React.ReactElement<any>;
    element: React.ReactElement<any>;
}

const Accordion: React.FC<TextProps> = ({ clickToOpen, clickToClose, element }) => {
    const [isOpen, setIsOpen] = useState(false);
    const toClose = <div onClick={() => setIsOpen(false)}>{clickToClose}</div>
    const toOpen = <div onClick={() => setIsOpen(true)}>{clickToOpen}</div>
    return (
        <div className="accordion">
            {isOpen && (
                element
            )}
            {isOpen ? toClose : toOpen}
        </div>
    );
};

export default Accordion;
