import React, { useState, useEffect } from 'react';
import './Carousel.css';

interface CarouselItem {
    text: React.ReactElement<Text>
    image: string;
}

interface CarouselProps {
    items: CarouselItem[];
    interval?: number;
}

const Carousel: React.FC<CarouselProps> = ({ items, interval = 5000 }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isPaused, setIsPaused] = useState(false);

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + items.length) % items.length);
    };

    useEffect(() => {
        const timer = setInterval(() => {
            if (!isPaused) {
                setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
            }
        }, interval);

        return () => clearInterval(timer);
    }, [items.length, interval, isPaused]);

    return (
        <div className="carousel-container"
            onMouseEnter={() => setIsPaused(true)}
            onMouseLeave={() => setIsPaused(false)}>
            <div className="carousel-slides" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
                {items.map((item, index) => (
                    <div key={index} className="carousel-content">
                        <div className="carousel-text">
                            <div className="carousel-text-wrapper">
                                {item.text}
                            </div>
                        </div>
                        <div className="carousel-image-container">
                            <img
                                src={item.image}
                                alt={`Carousel ${index}`}
                                className="carousel-image"
                            />
                        </div>
                    </div>
                ))}
            </div>
            <button onClick={handlePrev} className="carousel-button prev">&#10094;</button>
            <button onClick={handleNext} className="carousel-button next">&#10095;</button>
        </div>
    );
};

export default Carousel;
