import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Home from './pages/Home/Home';
import Values from './pages/Values';
import Story from './pages/Story';
import Approach from './pages/Approach';
import Services from './pages/Services';
import Innovations from './pages/Innovations';
import Healthcare from './pages/Healthcare';
import Team from './pages/Team';
import Contact from './pages/Contact/Contact_v1';
import './App.css';

const App: React.FC = () => {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/values" element={<Values />} />
        <Route path="/story" element={<Story />} />
        <Route path="/approach" element={<Approach />} />
        <Route path="/services" element={<Services />} />
        <Route path="/innovations" element={<Innovations />} />
        <Route path="/healthcare" element={<Healthcare />} />
        <Route path="/team" element={<Team />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;