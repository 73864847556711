import React from 'react';
import './Headshot.css';

interface HeadshotProps {
    src: string;
    alt: string;
}

const Headshot: React.FC<HeadshotProps> = ({ src, alt }) => {
    return (
        <div className="headshot">
            <img
                src={src}
                alt={alt}
            />
        </div>
    )
}

export default Headshot;
