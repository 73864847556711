import React from 'react';
import './TwoColumns.css';

interface TwoColumnsProps {
    left: React.ReactElement<any>;
    right: React.ReactElement<any>;
    leftAboveInMobile: boolean;
}

const TwoColumns: React.FC<TwoColumnsProps> = ({ left, right, leftAboveInMobile=true }) => {
    const className = leftAboveInMobile ? "two-columns-regular" : "two-columns-reverse";

    return (
        <div className={"two-columns " + className}>
            {left}
            {right}
        </div>
    )
}

export default TwoColumns;
