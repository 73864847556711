import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import './Link.css';

interface LinkProps {
    to: string;
    element: React.ReactElement;
}

const Link: React.FC<LinkProps> = ({ to, element }) => {
    const location = useLocation();
    useEffect(() => {
        if (location.hash) {
            const id = location.hash.replace("#", "");
            const element = document.getElementById(id);
            if (element) {
                element.scrollIntoView();
            }
        }
    }, [location]);

    return (
        <HashLink to={to} className="link">
            {element}
        </HashLink>
    )
};

export default Link;