import React from 'react';
import OurStory from './Story/Story';
import OurServices from './Services/Services';
import OurServicesCard from './Services/ServicesCard';

import Page from '../../components/Page/Page';
import Link from '../../components/Link/Link';
import Vertical from '../../components/Vertical/Vertical';
import Icon from '../../components/Icon/Icon';
import Carousel from '../../components/Carousel/Carousel';
import Text from '../../components/Text/Text';
import Background from '../../components/Background/Background';
import Button from '../../components/Button/Button';
import Center from '../../components/Center/Center';
import Border from '../../components/Border/Border';
import TwoColumns from '../../components/TwoColumns/TwoColumns';
import Expand from '../../components/Expand/Expand';
import Brighten from '../../components/Brighten/Brighten';

const Home = () => {
    const carouselItems = [
        {
            text: <Text texts={[
                <Center element={
                    <h2 style={{color: `#3c4f73`}}>Healthcare Solutions</h2>
                }/>,
                <h6>Our AI model makes it possible for you to build innovative applications ranging from workplace inclusion to adaptive training.</h6>,
                <Center element={
                    <Link
                        to={`/healthcare#`}
                        element={
                            <Button element={
                                <Border
                                    backgroundColor={`#d5dae3`}
                                    element={
                                        <Text
                                            color={`#000`}
                                            texts={[
                                                <p>Learn More</p>,
                                            ]}
                                        />
                                    }
                                />
                            }/>
                        }
                    />
                }/>
            ]}/>,
            image: '/images/healthcare/autism.png',
        },
        {
            text: <Text texts={[
                <Center element={
                    <h2 style={{color: `#3c4f73`}}>Therapist's Companion</h2>
                }/>,
                <h6>Our system provides a high-accuracy screener for learning disabilities and generates individualized profiles to identify appropriate interventions.</h6>,
                <Center element={
                    <Link
                        to={`/innovations#therapists-companion`}
                        element={
                            <Button element={
                                <Border
                                    backgroundColor={`#d5dae3`}
                                    element={
                                        <Text
                                            color={`#000`}
                                            texts={[
                                                <p>Learn More</p>,
                                            ]}
                                        />
                                    }
                                />
                            }/>
                        }
                    />
                }/>
            ]}/>,
            image: '/images/innovations/company-manager-focusing-hard-on-difficult-task-2023-11-27-05-08-46-utc.jpg',
        },
        {
            text: <Text texts={[
                <h2 style={{color: `#3c4f73`}}>Socially Assistive Robots that Express True Mental State</h2>,
                <h6>Our robot detects emotions from speech patterns and expresses them through robot gestures and facial expressions.</h6>,
                <Center element={
                    <Link
                        to={`/innovations#socially-assistive-robots`}
                        element={
                            <Button element={
                                <Border
                                    backgroundColor={`#d5dae3`}
                                    element={
                                        <Text
                                            color={`#000`}
                                            texts={[
                                                <p>Learn More</p>,
                                            ]}
                                        />
                                    }
                                />
                            }/>
                        }
                    />
                }/>
            ]}/>,
            image: '/images/innovations/robot-assistant-concept-woman-using-information-r-2023-11-27-05-34-03-utc.jpeg',
        },
        {
            text: <Text texts={[
                <Center element={
                    <h2 style={{color: `#3c4f73`}}>Enhanced Mental Health Support Through AI</h2>
                }/>,
                <h6>Our system detects social isolation in the elderly by analyzing their speech and voice tone. It also adapts to individual speech variations.</h6>,
                <Center element={
                    <Link
                        to={`/innovations#mental-health-support`}
                        element={
                            <Button element={
                                <Border
                                    backgroundColor={`#d5dae3`}
                                    element={
                                        <Text
                                            color={`#000`}
                                            texts={[
                                                <p>Learn More</p>,
                                            ]}
                                        />
                                    }
                                />
                            }/>
                        }
                    />
                }/>,
            ]}/>,
            image: '/images/innovations/ai-robot-consoling-a-sad-lonely-man-2023-11-27-05-08-22-utc.jpg',
        },
    ];





    const ourServicesCards = [
        <OurServicesCard
            text='Design, Development, & Evaluation'
            desc='We use state-of-the-art algorithms and methods, providing robustness, scalability, and seamless integration with your existing systems'
            image={
                <Link
                    to={`/services#development`}
                    element={
                        <Icon
                            src='/images/services/Spark-Logo-only-white-face-black-lines.png'
                            alt={`development`}
                        />
                    }
                />
            }
        />,
        <OurServicesCard
            text='Human Interaction'
            desc='Our expertise in multimodal analysis supports the development of screening tools that incorporate speech, vocal and facial affect, and visual human interaction'
            image={
                <Link
                    to={`/services#hri`}
                    element={
                        <Icon
                            src={`/images/services/HRI.png`}
                            alt={`hri`}
                        />
                    }
                />
            }
        />,
        <OurServicesCard
            text='Data Collection, Analysis, & Preparation'
            desc='We use natural language processing to analyze unstructured text and extract features needed to build AI models'
            image={
                <Link
                    to={`/services#data`}
                    element={
                        <Icon
                            src={`/images/services/Data-Science.png`}
                            alt={`data`}
                        />
                    }
                />
            }
        />,
        <OurServicesCard
            text='Research Science'
            desc='We support grant writing, literature reviews, model development, model evaluation, and writing papers'
            image={
                <Link
                    to={`/services#research-science`}
                    element={
                        <Icon
                            src={`/images/services/research-microscope.png`}
                            alt={`research-science`}
                        />
                    }
                />
            }
        />,
        <OurServicesCard
            text='Product Evaluation'
            desc='We use statistical techniques to evaluate screening and other tools under development or in the market to ensure their effectiveness and reliability'
            image={
                <Link
                    to={`/services#product-evaluation`}
                    element={
                        <Icon
                            src={`/images/services/product-eval.png`}
                            alt={`product-evaluation`}
                        />
                    }
                />
            }
        />,
    ]

    // Low Priority:
    // TODO: Font for Text: Tamil MN for titles, headers, etc: Cormorant Garamond
    // TODO: Services page facelift
    // TODO: Background image component
    // TODO: Footer background

    // Important:
    // TODO: Show/Hide Example needs animation
    // TODO: Make carousel scroll only right/left and not jump to start/end
    // TODO: Setup for eco site (instead of home computer)
    // TODO: Add subheaders to innovations
    // TODO: Update images for autism and dyslexia

    // Critical:
    // TODO: Fix header on mobile
    // TODO: Fix carousel on mobile
    // TODO: Update contact page
    // TODO: SSL


    return (
        <div className="home">
            <Carousel items={carouselItems} />
            <OurStory />
            <OurServices cards={ourServicesCards}/>
        </div>
    );
}

export default Home;
