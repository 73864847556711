import React from 'react';
import './Footer.css';

const Footer: React.FC = () => {
    return (
        <div className="footer">
            <p>Contents Copyright © 2024 Spark Interventions, LLC</p>
            <p className="hide">|</p>
            <a href="mailto:info@sparkinterventions.ai">info@sparkinterventions.ai</a>
            <p className="hide">|</p>
            <a href="/contact">Contact Us</a>
        </div>
    );
};

export default Footer;
