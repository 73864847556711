import React from 'react';
import './Border.css';

interface BorderProps {
    element: React.ReactElement<any>;
    backgroundColor?: string;
}

const Border: React.FC<BorderProps> = ({ element, backgroundColor }) => {
    return (
        <div className="border" style={{ backgroundColor: backgroundColor }}>
            {element}
        </div>
    )
}

export default Border;
