import React from 'react';
import './Page.css';

interface PageProps {
    elements: React.ReactElement<any>[];
}

const Page: React.FC<PageProps> = ({ elements }) => {
    return (
        <div className="page">
            {elements.map((element, index) => 
                React.cloneElement(element, { key: index })
            )}
        </div>
    )
}

export default Page;
