import React from 'react';
import Page from '../components/Page/Page';
import TwoColumns from '../components/TwoColumns/TwoColumns';
import Text from '../components/Text/Text';
import Image from '../components/Image/Image';
import Border from '../components/Border/Border';
import Accordion from '../components/Accordion/Accordion';
import Brighten from '../components/Brighten/Brighten';
import Flex40 from '../components/Flex40/Flex40';

const Approach: React.FC = () => {
    return (
        <div style={{color: `#3c4f73`}}>
        <Page elements={[
            <Text texts={[
                <h1>Our Approach</h1>,
                <p>At Spark Interventions, we specialize in harnessing the power of artificial intelligence and machine learning to solve complex problems and drive innovation in mental health care and learning disabilities.</p>,
                <p>Our comprehensive suite of services is designed to provide researchers, healthcare providers, and organizations with the tools they need to optimize cognitive capabilities and enhance mental well-being.</p>,
            ]}/>,
            <Border element={
                <TwoColumns
                    leftAboveInMobile={true}
                    left={
                        <Flex40 element={
                            <Image
                                src={`/images/approach/christin-hume-Hcfwew744z4-unsplash.jpg`}
                                alt={`Designing Solutions`}
                            />
                        }/>
                    }
                    right={
                        <Text texts={[
                            <h4>Designing Solutions</h4>,
                            <p>
                                We begin by deeply understanding your specific business challenges.
                                Our team collaborates closely with you to identify pain points and areas for improvement,
                                ensuring our solutions are tailored to meet your unique needs.
                            </p>,
                            <Accordion
                                clickToOpen={
                                    <Brighten element={
                                        <p style={{ color: `#e08132` }}>
                                            Read More »
                                        </p>
                                    }/>
                                }
                                clickToClose={
                                    <Brighten element={
                                        <p style={{ color: `#e08132` }}>
                                            Read Less «
                                        </p>
                                    }/>
                                }
                                element={
                                    <div>
                                        <p>
                                            We design custom AI/ML models that leverage state-of-the-art algorithms and methods.
                                            Our models are crafted to be robust, scalable, and seamlessly integrated with your existing systems.
                                            Rigorous testing and validation ensure they meet desired performance and accuracy metrics.
                                        </p>
                                        <p>
                                            Our data experts employ Natural Language Processing (NLP) to analyze unstructured text and extract valuable information.
                                            We handle data acquisition, cleansing, and visualization to prepare high-quality data for model development.
                                        </p>
                                    </div>
                                }
                            />
                        ]}/>
                    }
                />
            }/>,
            <Border element={
                <TwoColumns
                    leftAboveInMobile={false}
                    left={
                        <Text texts={[
                            <h4>Delivering Solutions</h4>,
                            <p>
                                The initial phase of our delivery process involves preparing and standardizing text and audio data.
                                We provide preprocessing code, data quality reports, and feature engineering toolkits to set the foundation for model development.
                            </p>,
                            <Accordion
                                clickToOpen={
                                    <Brighten element={
                                        <p style={{ color: `#e08132` }}>
                                            Read More »
                                        </p>
                                    }/>
                                }
                                clickToClose={
                                    <Brighten element={
                                        <p style={{ color: `#e08132` }}>
                                            Read Less «
                                        </p>
                                    }/>
                                }
                                element={
                                    <div>
                                        <p>
                                            In the proof of concept (POC) development phase, we develop and test a preliminary AI model using preprocessed data.
                                            This includes model selection, initial training, and iterative refinement, resulting in a working prototype,
                                            POC performance report, and model training and validation pipeline.
                                        </p>
                                        <p>
                                            As we transition to beta release, we refine the model and system architecture for broader user testing.
                                            System enhancement for scalability, comprehensive testing, user training, and documentation are all part of this phase.
                                            We deliver the beta version of the model, system integration code, a beta test plan, and a performance monitoring toolkit.
                                        </p>
                                        <p>
                                            We continuously explore ways to improve our models,
                                            including integrating additional modalities like vocal
                                            and facial affect analysis to enhance the sensitivity
                                            and accuracy of our tools.
                                        </p>
                                    </div>
                                }
                            />,
                        ]}/>
                    }
                    right={
                        <Flex40 element={
                            <Image
                                src={`/images/approach/shahadat-rahman-BfrQnKBulYQ-unsplash.jpg`}
                                alt={`Delivering Solutions`}
                            />
                        }/>
                    }
                />
            }/>,
            <Border element={
                <TwoColumns
                    leftAboveInMobile={true}
                    left={
                        <Flex40 element={
                            <Image
                                src={`/images/approach/nicholas-cappello-Wb63zqJ5gnE-unsplash.jpg`}
                                alt={`Evaluating Solutions`}
                            />
                        }/>
                    }
                    right={
                        <Text texts={[
                            <h4>Evaluating Solutions</h4>,
                            <p>
                                We ensure our models meet the highest standards of performance
                                and accuracy through rigorous testing and validation.
                                Our evaluation processes guarantee robustness and reliability
                                across various conditions and scenarios.
                            </p>,
                            <Accordion
                                clickToOpen={
                                    <Brighten element={
                                        <p style={{ color: `#e08132` }}>
                                            Read More »
                                        </p>
                                    }/>
                                }
                                clickToClose={
                                    <Brighten element={
                                        <p style={{ color: `#e08132` }}>
                                            Read Less «
                                        </p>
                                    }/>
                                }
                                element={
                                    <div>
                                        <p>
                                            Throughout the development phases, we maintain a continuous
                                            feedback loop with key stakeholders to refine and adjust our
                                            solutions based on real-time performance data.
                                            This iterative process helps us align our models with clinical
                                            needs and operational standards.
                                        </p>
                                        <p>
                                            During and after the beta phase, we monitor the performance of
                                            deployed solutions and provide detailed reports to stakeholders,
                                            helping to assess the impact and effectiveness of our solutions
                                            in real-world settings.
                                        </p>
                                    </div>
                                }
                            />
                        ]}/>
                    }
                />
            }/>,
            <Border element={
                <TwoColumns
                    leftAboveInMobile={false}
                    left={
                        <Text texts={[
                            <h4>Collaborative and Impact-Oriented Approach</h4>,
                            <p>
                                We work closely with our clients to ensure our solutions
                                align with their strategic goals and priorities.
                                This collaborative approach leads to more effective
                                and impactful outcomes, enhancing service delivery
                                and client satisfaction.
                            </p>,
                            <Accordion
                                clickToOpen={
                                    <Brighten element={
                                        <p style={{ color: `#e08132` }}>
                                            Read More »
                                        </p>
                                    }/>
                                }
                                clickToClose={
                                    <Brighten element={
                                        <p style={{ color: `#e08132` }}>
                                            Read Less «
                                        </p>
                                    }/>
                                }
                                element={
                                    <div>
                                        <p>
                                            Our services are designed to provide long-term benefits,
                                            from early detection and personalized intervention planning
                                            to ongoing support. We aim to improve the quality of life
                                            for individuals facing mental health challenges and learning
                                            disabilities through innovative and impactful solutions.
                                        </p>
                                        <p>
                                            By combining our expertise in AI/ML model development,
                                            data analysis, and a human-centered approach, Spark Interventions
                                            designs, delivers, and evaluates tailored solutions that
                                            address your unique business challenges, ensuring innovative
                                            and impactful outcomes.
                                        </p>
                                    </div>
                                }
                            />
                        ]}/>
                    }
                    right={
                        <Flex40 element={
                            <Image
                                src={`/images/approach/dylan-gillis-KdeqA3aTnBY-unsplash.jpg`}
                                alt={`Collaborative Approach`}
                            />
                        }/>
                    }
                />
            }/>,
        ]}/>
        </div>
    );
};

export default Approach;
