import React from 'react';
import './Button.css';

interface ButtonProps {
    element: React.ReactElement<any>;
}

const Button: React.FC<ButtonProps> = ({ element }) => {
    return (
        <div className="link-button">
            {element}
        </div>
    );
};

export default Button;
