import React, { useEffect } from 'react';
import './Story.css';
import Center from '../../../components/Center/Center';
import Text from '../../../components/Text/Text';
import Button from '../../../components/Button/Button';
import Border from '../../../components/Border/Border';
import Link from '../../../components/Link/Link';

const InfoSection: React.FC = () => {
    return (
        <div className="story">
            <Center element={
                <Text texts={[
                    <h2 className="story-text">We Provide AI Solutions for the Healthcare Market</h2>,
                    <p className="story-text">Spark Interventions designs AI solutions for mental health care teams and their clients to ensure unbiased and accessible mental health care for all. Our solutions use AI to empower individuals to unlock their potential at every life stage. From childhood to adulthood, our AI detects, analyzes, and helps overcome learning difficulties, monitors emotional states, provides personalized interventions, and evaluates outcomes.</p>,
                    <Center element={
                        <Link
                            to={`/story#`}
                            element={
                                <Button element={
                                    <Border
                                        backgroundColor={`#e08132`}
                                        element={
                                            <Text
                                                color={`#fff`}
                                                texts={[
                                                    <p>Learn More</p>,
                                                ]}
                                            />
                                        }
                                    />
                                }/>
                            }
                        />
                    }/>,
                ]}/>
            }/>
        </div>
    );
};

export default InfoSection;
