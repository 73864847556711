import React, { useState } from 'react';
import './Contact_v1.css';
import TwoColumns from '../../components/TwoColumns/TwoColumns';
import Center from '../../components/Center/Center';
import Text from '../../components/Text/Text';
import Page from '../../components/Page/Page';

const Contact = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        title: '',
        email: '',
        phone: '',
        topic: '',
        message: ''
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        console.log(formData);  // Replace with actual submission logic
        alert('Form submitted. We will contact you soon!');
    };

    return (
        <Page elements={[
            <div className="contact-container">
                <div className="contact-info">
                    <h2>Interested in learning how we can support your business?</h2>
                    <p>Complete our form to share your requirements, and we'll contact you promptly to discuss how we can help.</p>
                    <div className="contact-email">
                        <img src="/images/contact/email.png" alt="Email Icon" />
                        <span><a href="mailto:info@sparkinterventions.ai">info@sparkinterventions.ai</a></span>
                    </div>
                </div>
                <div className="contact-form">
                    <form onSubmit={handleSubmit}>
                        <Text texts={[
                            <h4 style={{fontSize: `12pt`}}>*First Name</h4>,
                            <input type="text" name="firstName" placeholder="" required onChange={handleChange} />,
                        ]}/>
                        
                        <Text texts={[
                            <h4 style={{fontSize: `12pt`}}>*Last Name</h4>,
                            <input type="text" name="lastName" placeholder="" required onChange={handleChange} />,
                        ]}/>

                        <Text texts={[
                            <h4 style={{fontSize: `12pt`}}>Title</h4>,
                            <select name="title" onChange={handleChange} required>
                                <option value="">Select Title</option>
                                <option value="Mr">Mr.</option>
                                <option value="Ms">Ms.</option>
                                <option value="Mrs">Mrs.</option>
                                <option value="Dr">Dr.</option>
                            </select>,
                        ]}/>

                        <Text texts={[
                            <h4 style={{fontSize: `12pt`}}>*Email</h4>,
                            <input type="email" name="email" placeholder="" required onChange={handleChange} />,
                        ]}/>
                        
                        <Text texts={[
                            <h4 style={{fontSize: `12pt`}}>*Phone</h4>,
                            <input type="tel" name="phone" placeholder="" required onChange={handleChange} />,
                        ]}/>

                        <Text texts={[
                            <h4 style={{fontSize: `12pt`}}>Message</h4>,
                            <textarea name="message" placeholder="Your message" onChange={handleChange}></textarea>,
                        ]}/>
                        
                        <button type="submit">Send</button>
                    </form>
                </div>
                <div className="filler"></div>
            </div>
        ]}/>
    );
};

export default Contact;
