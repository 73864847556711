import React from 'react';
import './Image.css';

interface ImageProps {
    src: string;
    alt: string;
    caption?: string;
}

const Image: React.FC<ImageProps> = ({ src, alt, caption }) => {
    return (
        <figure className="image">
            <img src={src} alt={alt}/>
            {caption && <figcaption className="caption">{caption}</figcaption>}
        </figure>
    )
}

export default Image;
