import React, { useState } from 'react';
import Border from '../components/Border/Border';
import Page from '../components/Page/Page';
import TwoColumns from '../components/TwoColumns/TwoColumns';
import Text from '../components/Text/Text';
import Icon from '../components/Icon/Icon';
import Accordion from '../components/Accordion/Accordion';
import Brighten from '../components/Brighten/Brighten';
import Center from '../components/Center/Center';

const Services = () => {
    return (
        <Page elements={[
            <Text texts={[
                <h1>Our Services Deliver Tailored Solutions</h1>,
            ]}/>,
            <Border element={
                <TwoColumns
                    leftAboveInMobile={true}
                    left={
                        <Icon
                            src={`/images/services/Spark-Logo-only-white-face-black-lines.png`}
                            alt={`development`}
                        />
                    }
                    right={
                        <Text texts={[
                            <h2>Design, Development, and Evaluation of AI/ML Models</h2>,
                            <p>
                                We craft custom AI/ML models tailored to your specific business needs,
                                leveraging supervised, unsupervised, and generative techniques to ensure
                                adaptability and effectiveness.
                            </p>,
                            <p>
                                We build and implement these models using
                                state-of-the-art algorithms and methods, providing robustness, scalability,
                                and seamless integration with your existing systems.
                                Rigorous testing and validation ensure our models meet the highest performance and accuracy metrics,
                                giving you confidence in their reliability.
                            </p>,
                            <Accordion
                                clickToOpen={
                                    <Brighten element={
                                        <p style={{ color: `#e08132` }}>
                                            Read More »
                                        </p>
                                    }/>
                                }
                                clickToClose={
                                    <Brighten element={
                                        <p style={{ color: `#e08132` }}>
                                            Read Less «
                                        </p>
                                    }/>
                                }
                                element={
                                    <div>
                                        <p>
                                            Imagine developing a custom AI model to assist students with dyslexia by providing
                                            personalized reading exercises and real-time feedback. The model could leverage supervised
                                            learning techniques to adapt to each student's progress, offering tailored support to improve
                                            reading skills and confidence. Rigorous testing would ensure the model's accuracy and
                                            effectiveness in various educational settings.
                                        </p>
                                    </div>
                                }
                            />
                        ]}/>
                    }
                />
            }/>,
            
            <Border element={
                <TwoColumns
                    leftAboveInMobile={true}
                    left={
                        <Icon
                            src={`/images/services/research-microscope.png`}
                            alt={`research-science`}
                        />
                    }
                    right={
                        <Text texts={[
                            <h2>Research Science</h2>,
                            <p>
                                At Spark Interventions, we are committed to supporting Principal Investigators
                                (PIs) in their crucial research. We focus on understanding how individuals develop learning,
                                behavioral, memory, motor skills, and social communication.
                            </p>,
                            <p>
                                Our support extends to the PI’s
                                development of advanced screening tools for early detection of learning disorders
                                and personalized interventions. We also assist in the grant writing process,
                                showcasing how our innovative use of AI and Data Sciences can significantly
                                advance the understanding of learning disabilities.
                            </p>,
                            <Accordion
                                clickToOpen={
                                    <Brighten element={
                                        <p style={{ color: `#e08132` }}>
                                            Read More »
                                        </p>
                                    }/>
                                }
                                clickToClose={
                                    <Brighten element={
                                        <p style={{ color: `#e08132` }}>
                                            Read Less «
                                        </p>
                                    }/>
                                }
                                element={
                                    <p>
                                        Consider a scenario where our team supports a Principal Investigator (PI) in a study to develop
                                        advanced screening tools for early detection of Autism Spectrum Disorder (ASD). We could help
                                        create a tool that analyzes behavioral patterns, motor skills, and social communication
                                        indicators in young children by employing AI and data science techniques. This tool would
                                        aid in early diagnosis and personalized intervention planning.
                                    </p>
                                }
                            />
                        ]}/>
                    }
                />
            }/>,
            
            <Border element={
                <TwoColumns
                    leftAboveInMobile={true}
                    left={
                        <Icon
                            src={`/images/services/Data-Science.png`}
                            alt={`data`}
                        />
                    }
                    right={
                        <Text texts={[
                            <h2>Data Collection, Analysis, and Preparation</h2>,
                            <p>
                                We employ Natural Language Processing (NLP) to analyze unstructured text and extract information to support the training of AI models for screening learning differences. Our data acquisition, cleansing, and visualization expertise helps prepare data for analysis and model development.
                            </p>,
                            <Accordion
                                clickToOpen={
                                    <Brighten element={
                                        <p style={{ color: `#e08132` }}>
                                            Read More »
                                        </p>
                                    }/>
                                }
                                clickToClose={
                                    <Brighten element={
                                        <p style={{ color: `#e08132` }}>
                                            Read Less «
                                        </p>
                                    }/>
                                }
                                element={
                                    <p>
                                        Imagine utilizing Natural Language Processing (NLP) to analyze unstructured text from online mental health forums. By extracting valuable insights about user sentiment and common issues discussed, we could prepare a comprehensive dataset for training AI models. This data preparation would enable the development of a tool that provides mental health professionals with deeper insights into patient concerns and trends.
                                    </p>
                                }
                            />
                        ]}/>
                    }
                />
            }/>,
            
            <Border element={
                <TwoColumns
                    leftAboveInMobile={true}
                    left={
                        <Icon
                            src={`/images/services/HRI.png`}
                            alt={`hri`}
                        />
                    }
                    right={
                        <Text texts={[
                            <h2>Human Interaction</h2>,
                            <p>
                                At Spark Interventions, we delve into the intricacies of conversational interactions. Our expertise in multimodal analysis supports the development of screening tools that incorporate speech, vocal and facial affect, and visual human interaction. We bring a human touch to the world of AI and machine learning, making our services unique and invaluable.
                            </p>,
                            <Accordion
                                clickToOpen={
                                    <Brighten element={
                                        <p style={{ color: `#e08132` }}>
                                            Read More »
                                        </p>
                                    }/>
                                }
                                clickToClose={
                                    <Brighten element={
                                        <p style={{ color: `#e08132` }}>
                                            Read Less «
                                        </p>
                                    }/>
                                }
                                element={
                                    <p>
                                        Consider designing a conversational agent that integrates multimodal analysis, including speech, vocal affect, and facial expressions, to assist elderly individuals living alone. This agent could help monitor their mental and emotional well-being, providing companionship and alerting caregivers to any concerning changes. Our expertise in human interaction would ensure the agent is empathetic and effective in supporting the elderly.
                                    </p>
                                }
                            />
                        ]}/>
                    }
                />
            }/>,
            
            <Border element={
                <TwoColumns
                    leftAboveInMobile={true}
                    left={
                        <Icon
                            src={`/images/services/product-eval.png`}
                            alt={`product-evaluation`}
                        />
                    }
                    right={
                        <Text texts={[
                            <h2>Product Evaluation</h2>,
                            <p>
                                We use statistical techniques to evaluate screening and other tools under development or in the market to ensure their effectiveness and reliability.
                            </p>,
                            <Accordion
                                clickToOpen={
                                    <Brighten element={
                                        <p style={{ color: `#e08132` }}>
                                            Read More »
                                        </p>
                                    }/>
                                }
                                clickToClose={
                                    <Brighten element={
                                        <p style={{ color: `#e08132` }}>
                                            Read Less «
                                        </p>
                                    }/>
                                }
                                element={
                                    <p>
                                        Imagine using advanced statistical techniques to evaluate the effectiveness of a new digital therapeutic app designed for managing anxiety. We could assess the app's reliability and impact on reducing anxiety symptoms by analyzing user data and feedback. Our evaluation would provide actionable insights for the developers to improve the app and enhance its user effectiveness.
                                    </p>
                                }
                            />
                        ]}/>
                    }
                />
            }/>,
        ]}/>
    );
};

export default Services;
